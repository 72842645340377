/* src/ConfigManager.css */

.config-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.controls {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: row;
  background: #F5F5F5;
  border: #e2e2e2 1px solid;
  border-radius: 10px;
}

.create-mode,
.update-delete-mode {
  margin-top: 20px;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
}

.update-delete-mode {
  height: 25vw;
}

input {
  margin: 5px;
  width: 100%;
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #faf6f6;
  transition: 0.4s;
  border-radius: 34px;
  border: 2px solid #030c13;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  top: 2px;
  background-color: rgb(2, 0, 0);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #030c13;
}

input:checked + .slider:before {
  transform: translateX(26px);
  background-color: #faf6f6;
}

/* Key List Styles */
.ccm-key-list {
  height: 100%; /* Set a max height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px; /* Round the corners */
  width: 50%; /* Make it full width */
  margin-right: 50px;
}

.ccm-key-value {
  width: 50%;
  height: 100%; /* Set a max height for the scrollable area */
  margin-left: 50px;
}

.ccm-key-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.ccm-key-item:hover {
  background-color: #f49a6d; /* Highlight on hover */
}

.ccm-key-item.selected {
  background-color: #fa5400; /* Highlight selected item */
  color: rgb(252, 251, 251); /* Change text color for selected item */
  font-weight: bolder;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 65%;
  /* margin-top: 6px; */
  margin: auto;
}

.row {
  display: flex;
  align-items: center;
  padding: 1%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.create-label {
  padding: 12px 20px;
  display: inline-block;
  background-color: #fa5400;
  width: 90%;
  border-radius: 20px;
}

.service-dropdown {
  width: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.ccm-toggle {
  padding: 12px;
  width: 50%;
  flex-direction: row-reverse;
  display: flex;
  align-items: flex-end;
}

.select-service-dropdown {
  appearance: none;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

.css-b31c9l-container {
  width: 50%;
}

.config-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90%;
}

.ccm-key-value-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 20px;
  height: 100%;
  resize: none;
}

.modal-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 20px;
  height: 100%;
  resize: none;
}

.modal-textarea-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15vw;
}

.text-area-container {
  padding-bottom: 15px !important;
}

.toggleLabelActive {
  font-weight: bold;
}

#create-button-id {
  display: flex;
  flex-direction: column;
}
