.confirmation-modal .footer-buttons {
    margin: 0 auto;
}
.confirmation-modal > section {    
    min-height: 200px; 
    padding: 35px;
    padding-left: 0;
    padding-right: 0;
}

.confirmation-modal .dialog-header-container .title-heading {
    font-size: 15px;
    text-align: center;
    width: 100%;
}
.confirmation-modal .dialog-header-container header {
    padding: 0;
}

div.confirmation-modal {
    min-height: 200px;
    width: 454px;
    max-width: 560px;
}
.confirmation-modal .dialog-header-container {
    word-break: break-all;
}

