.react-select__control {
  height: 40px;
}

@media (width <= 1024px) {
  .react-select__control {
    height: 48px;
  }
}

.ReactTable {
  border: 1px solid #0000001a;
  flex-direction: column;
  display: flex;
  position: relative;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  border-collapse: collapse;
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  width: 100%;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-thead {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
}

.ReactTable .rt-thead.-headerGroups {
  background: #00000008;
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  font-size: inherit;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 3px;
  outline-width: 0;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid #00000005;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px #00000026;
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: 1px solid #0000000d;
  padding: 5px;
  line-height: normal;
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  position: relative;
  box-shadow: inset 0 0 #0000;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px #0009;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px #0009;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-th:focus {
  outline-width: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 100%;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-width: 8px;
  border-color: #fff0 #fff0 #fff0 #fff;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-width: 10px;
  border-color: #6660 #6660 #6660 #f7f7f7;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex-direction: column;
  flex: 99999 auto;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #00000005;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  flex-direction: column;
  flex: 1 0 auto;
  align-items: stretch;
  display: flex;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 0;
  padding: 7px 5px;
  transition: width .3s, min-width .3s, padding .3s, opacity .3s;
  overflow: hidden;
}

.ReactTable .rt-th.-hidden, .ReactTable .rt-td.-hidden {
  opacity: 0 !important;
  border: 0 !important;
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
}

.ReactTable .rt-expander {
  color: #0000;
  margin: 0 10px;
  display: inline-block;
  position: relative;
}

.ReactTable .rt-expander:after {
  content: "";
  cursor: pointer;
  border-top: 7px solid #000c;
  border-left: 5.04px solid #0000;
  border-right: 5.04px solid #0000;
  width: 0;
  height: 0;
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-90deg);
}

.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%)rotate(0);
}

.ReactTable .rt-resizer {
  cursor: col-resize;
  z-index: 10;
  width: 36px;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

.ReactTable .rt-tfoot {
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
  box-shadow: 0 0 15px #00000026;
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid #0000000d;
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: #00000008;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #0000000d;
}

.ReactTable .-pagination {
  z-index: 1;
  border-top: 2px solid #0000001a;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 3px;
  display: flex;
  box-shadow: 0 0 15px #0000001a;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  font-size: inherit;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 3px;
  outline-width: 0;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .-pagination .-btn {
  appearance: none;
  color: #0009;
  cursor: pointer;
  background: #0000001a;
  border: 0;
  border-radius: 3px;
  outline-width: 0;
  width: 100%;
  height: 100%;
  padding: 6px;
  font-size: 1em;
  transition: all .1s;
  display: block;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  color: #fff;
  background: #0000004d;
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  text-align: center;
  flex: 1;
}

.ReactTable .-pagination .-center {
  text-align: center;
  flex-flow: wrap;
  flex: 1.5;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.ReactTable .-pagination .-pageInfo {
  white-space: nowrap;
  margin: 3px 10px;
  display: inline-block;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  text-align: center;
  width: 70px;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  z-index: 1;
  pointer-events: none;
  color: #00000080;
  background: #fffc;
  padding: 20px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactTable .-loading {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  background: #fffc;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 0;
}

.ReactTable .-loading > div {
  text-align: center;
  color: #0009;
  width: 100%;
  font-size: 15px;
  transition: all .3s cubic-bezier(.25, .46, .45, .94);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-52%);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th, .ReactTable .rt-resizing .rt-td {
  cursor: col-resize;
  -webkit-user-select: none;
  user-select: none;
  transition: none !important;
}

.config-manager {
  flex-direction: column;
  align-items: center;
  margin: 20px;
  display: flex;
}

.controls {
  background: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  display: flex;
}

.create-mode, .update-delete-mode {
  border-radius: 5px;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  display: flex;
}

.update-delete-mode {
  height: 25vw;
}

input {
  width: 100%;
  margin: 5px;
}

.switch {
  width: 60px;
  height: 34px;
  margin: 0 10px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #faf6f6;
  border: 2px solid #030c13;
  border-radius: 34px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  background-color: #020000;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  transition: all .4s;
  position: absolute;
  top: 2px;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider {
  background-color: #030c13;
}

input:checked + .slider:before {
  background-color: #faf6f6;
  transform: translateX(26px);
}

.ccm-key-list {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50%;
  height: 100%;
  margin-right: 50px;
  overflow-y: auto;
}

.ccm-key-value {
  width: 50%;
  height: 100%;
  margin-left: 50px;
}

.ccm-key-item {
  cursor: pointer;
  padding: 10px;
  transition: background .3s;
}

.ccm-key-item:hover {
  background-color: #f49a6d;
}

.ccm-key-item.selected {
  color: #fcfbfb;
  background-color: #fa5400;
  font-weight: bolder;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 65%;
  margin: auto;
}

.row {
  align-items: center;
  padding: 1%;
  display: flex;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.create-label {
  background-color: #fa5400;
  border-radius: 20px;
  width: 90%;
  padding: 12px 20px;
  display: inline-block;
}

.service-dropdown {
  flex-direction: column;
  align-items: center;
  width: 50%;
  display: flex;
}

.ccm-toggle {
  flex-direction: row-reverse;
  align-items: flex-end;
  width: 50%;
  padding: 12px;
  display: flex;
}

.select-service-dropdown {
  appearance: none;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: .25rem;
  width: 100%;
  padding: .675em 6em .675em 1em;
  font-size: 1.15rem;
}

.css-b31c9l-container {
  width: 50%;
}

.config-group {
  flex-direction: row;
  align-items: center;
  height: 90%;
  display: flex;
}

.ccm-key-value-textarea, .modal-textarea {
  resize: none;
  border: 1px solid #ccc;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 12px;
}

.modal-textarea-div {
  flex-direction: column;
  align-items: center;
  height: 15vw;
  display: flex;
}

.text-area-container {
  padding-bottom: 15px !important;
}

.toggleLabelActive {
  font-weight: bold;
}

#create-button-id {
  flex-direction: column;
  display: flex;
}

.modal-overlay {
  z-index: 111;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 50%;
  padding: 20px;
  box-shadow: 0 4px 8px #0003;
}

.modal-content h2 {
  margin-top: 0;
}

.tool-tip .tool-tip-content {
  box-sizing: border-box;
  z-index: 1;
  background-color: #f9f9f9;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  min-width: 400px;
  min-height: 70px;
  margin-top: 30px;
  margin-left: 80px;
  padding: 10px;
  font-size: 12px;
  display: none;
  position: absolute;
  box-shadow: 0 0 6px 1px #c9c9c980;
}

.tool-tip:hover .tool-tip-content {
  display: block;
}

.tool-tip svg {
  color: #fff;
  cursor: pointer;
  z-index: 1;
  background-color: #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-top: 8px;
  position: absolute;
  left: 100px;
}

.order-info {
  box-sizing: border-box;
  background-color: #f9f9f9;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  min-width: 100%;
  min-height: 40px;
  box-shadow: 12px 12px 6px 1px #c9c9c980;
}

.order-lookup-search {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.order-lookup-search input {
  flex-grow: 1;
}

.order-lookup-search button {
  flex-shrink: 0;
  width: 100px;
  margin-top: 0;
}

.order-lookup-results {
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
  display: flex;
}

.no-results {
  font-size: 18pt;
}

.order-header {
  border-bottom: 1px solid #cfcfcf;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.order-identifiers {
  text-align: left;
  padding: 10px;
}

.order-status-group {
  text-align: right;
}

.order-number {
  font-size: 20px;
  display: block;
}

.frn, .fulfillment-type {
  color: gray;
  display: block;
}

.order-status {
  font-size: 20px;
}

.items {
  align-items: left;
  text-align: left;
  flex-direction: column;
  padding-left: 10px;
  display: flex;
}

.item-details {
  border-bottom: 1px solid #cfcfcf;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.order-image img {
  width: 200px;
  height: 200px;
  margin-right: 10px;
}

.item-info {
  align-items: left;
  text-align: left;
  flex-direction: row;
  display: flex;
}

.item-identifiers {
  align-items: left;
  text-align: left;
  flex-direction: column;
  display: flex;
}

.item-status {
  text-align: right;
}

.item-status .status {
  font-size: 18px;
}

.fulfillment {
  border-top: 1px solid #cfcfcf;
}

.fulfillments {
  text-align: left;
}

.order-actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.tracking-number {
  padding: 10px;
}

.order-actions button {
  margin-right: 8px;
}

.json-buttons {
  padding: 10px;
}

.json-buttons > div {
  margin: 5px 0;
}

.order {
  float: left;
}

.order-json {
  width: 100%;
  height: 500px;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  cursor: pointer;
  border: 1px solid #0000;
  border-bottom: none;
  padding: 6px 12px;
  list-style: none;
  display: inline-block;
  position: relative;
  bottom: -1px;
}

.react-tabs__tab--selected {
  color: #000;
  background: #fff;
  border-color: #aaa;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: graytext;
  cursor: default;
}

.react-tabs__tab:focus {
  border-color: #0188fe;
  outline: none;
  box-shadow: 0 0 5px #0188fe;
}

.react-tabs__tab:focus:after {
  content: "";
  background: #fff;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: -4px;
  right: -4px;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.rectangle {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  margin: 10px;
}

.rectangle li {
  border-bottom: 1px solid #cfcfcf;
}

.rectangle li:last-child {
  border-bottom: none;
}

.confirmation-modal .footer-buttons {
  margin: 0 auto;
}

.confirmation-modal > section {
  min-height: 200px;
  padding: 35px 0;
}

.confirmation-modal .dialog-header-container .title-heading {
  text-align: center;
  width: 100%;
  font-size: 15px;
}

.confirmation-modal .dialog-header-container header {
  padding: 0;
}

div.confirmation-modal {
  width: 454px;
  max-width: 560px;
  min-height: 200px;
}

.confirmation-modal .dialog-header-container {
  word-break: break-all;
}

.popup-content {
  box-sizing: border-box;
  z-index: 1;
  background-color: #f9f9f9;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  min-width: 100px;
  min-height: 40px;
  font-size: 12px;
  display: none;
  position: absolute;
  box-shadow: 0 0 6px 1px #c9c9c980;
}

.popup-content li span {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.popup .popup-content ul li:hover {
  background-color: #deebff;
}

.popup:hover .popup-content {
  display: block;
}

.success-message {
  box-sizing: border-box;
  color: #27ae60;
  float: right;
  background-color: #dcfdea;
  border: 1px solid #27ae60;
  border-radius: 2px;
  width: 228px;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
}

.overlay {
  z-index: 111;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.overlay-content {
  background: #fff;
  border-radius: 8px;
  width: 70%;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px #0003;
}

.overlay-content-loading {
  background: #fff;
  border-radius: 8px;
  width: 30%;
  padding: 20px;
  box-shadow: 0 4px 8px #0003;
}

.overlay-content h2 {
  margin-top: 0;
}

.tooltip-custom {
  cursor: pointer;
  float: right;
  padding-top: 10px;
  position: relative;
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #000;
  border-radius: 6px;
  width: 180px;
  padding: 5px 0;
  position: absolute;
}

.tooltip-custom .tooltiptext:after {
  content: " ";
  border: 5px solid #0000;
  border-right-color: #000;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  right: 100%;
}

.tooltip-custom:hover .tooltiptext {
  visibility: visible;
}

.outer-container {
  justify-content: center;
  align-items: baseline;
  display: flex;
}

.download-icon {
  width: 36px;
  height: 36px;
}

.create-key {
  text-align: right;
  margin-top: 0%;
  padding-top: 0%;
}

.create-key-button {
  text-align: right;
  margin-top: 0%;
  padding-top: 0%;
  right: 0;
}

.input-container {
  gap: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.input-container > * {
  flex: 2;
  padding-inline: 20px;
}

.toggle-container {
  gap: 10px;
  padding: 10px 20px;
  display: flex;
}

.toggle-container > * {
  flex: 2;
  padding-inline: 20px;
}

.select-container {
  text-align: left;
  z-index: 1004;
  position: relative;
}

.select-container-valueType {
  text-align: left;
  z-index: 1003;
  position: relative;
}

.select-container-group {
  text-align: left;
  z-index: 1002;
  position: relative;
}

.select-container-value {
  text-align: left;
  z-index: 1001;
  position: relative;
}

.req-paragraph {
  color: red;
  padding-top: 10px;
  font-size: 12px;
}

.loading {
  padding-top: 24px;
}

.container {
  text-align: center;
}

.toggle {
  justify-content: left;
  align-items: center;
  padding-top: 30px;
  display: flex;
}

.custom-toggle {
  width: 50px;
  height: 24px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.custom-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-toggle-slider {
  cursor: pointer;
  background-color: #ccc;
  border-radius: 24px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.custom-toggle-slider:before {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.custom-toggle input:checked + .custom-toggle-slider {
  background-color: #000;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  transform: translateX(26px);
}

.toggleLabel {
  margin-right: 10px;
  font-size: 14px;
}

.bulk-edit-article-height {
  height: 30vw;
}

.key-list {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 25vw;
  margin-right: 50px;
  overflow-y: auto;
}

.key-value {
  width: 100%;
  height: 100%;
  margin-left: 50px;
}

.key-item, .edit-key-item {
  cursor: pointer;
  padding: 10px;
  transition: background .3s;
}

.key-item:hover {
  background-color: #f49a6d;
}

.edit-key-item:hover {
  background-color: #ececec;
}

.key-item.selected {
  color: #fcfbfb;
  background-color: #fa5400;
  font-weight: bolder;
}

.filter-overlay {
  z-index: 111;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.filter-overlay-content {
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 30%;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px #0003;
}

.close-container {
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.button-container {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.button-container button {
  margin: 0 10px;
}

.overlay-select {
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.show-filter-container {
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-left: 40px;
  display: flex;
}

.show-filter-item {
  border: 1px solid #000;
  align-items: center;
  padding: 5px;
  display: flex;
  position: relative;
}

.parent-edit-class .react-select__placeholder {
  color: #cac9c9 !important;
}

.bulk-chip {
  color: #fff;
  cursor: pointer;
  background-color: #707072;
  border: none;
  border-radius: 14px;
  align-items: center;
  padding: 4px 2px 4px 6px;
  font-size: 11px;
  font-weight: bold;
  display: inline-flex;
}

.bulk-chip .bulk-close-icon {
  cursor: pointer;
  color: #707072;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  line-height: 1;
  display: inline-flex;
}

.bulk-chip .bulk-close-icon:hover {
  color: #0a0606;
}

.country-stores-container {
  border: 2px solid #000;
  width: 300px;
  margin: auto;
  padding: 20px;
}

.reset-button {
  margin: 10px auto;
  padding: 5px 10px;
  display: block;
}

.country-section {
  margin-bottom: 20px;
}

.country-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.country-header h3 {
  margin: 0;
}

.trash-button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 18px;
}

.stores-container {
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
  display: flex;
}

.store-item {
  border: 1px solid #000;
  align-items: center;
  padding: 5px;
  display: flex;
  position: relative;
}

.delete-store-button {
  cursor: pointer;
  background: none;
  border: none;
  margin-left: 5px;
  font-size: 12px;
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.sidebar {
  text-align: left;
  white-space: nowrap;
  word-wrap: unset;
  z-index: 9999;
  background: #fa5400;
  width: 55px;
  height: 100%;
  padding: 5px 5px 5px 15px;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden visible;
  box-shadow: 1px 0 7px #111;
}

.sidebar svg {
  height: 48px;
}

.label {
  vertical-align: top;
  display: none;
  transform: translateX(-100%);
}

.sidebar:hover {
  width: 280px;
}

.sidebar:hover .label {
  padding-top: 12px;
  padding-left: 12px;
  display: inline-block;
  transform: translateX(0);
}

.sidebar:focus {
  outline: none;
}

.sidebar a {
  color: #e5e5e5;
}

.sidebar a:hover, .sidebar a:active {
  color: #333;
}

.sidebar-item {
  color: #e5e5e5;
  height: 48px;
  transition: all .7s;
  overflow: hidden;
}

.subnav {
  margin-left: 29px;
  display: none;
}

.sidebar-item:hover .subnav {
  display: block;
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  flex-direction: column;
  display: inline-flex;
}

.rdrDateDisplay {
  justify-content: space-between;
  display: flex;
}

.rdrDateDisplayItem {
  text-align: center;
  color: inherit;
  flex: 1;
  width: 0;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: .833em;
}

.rdrDateDisplayItem input {
  text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  justify-content: space-between;
  display: flex;
}

.rdrMonthAndYearPickers {
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  flex-direction: row;
  display: flex;
}

.rdrMonth {
  width: 27.667em;
}

.rdrWeekDays {
  display: flex;
}

.rdrWeekDay {
  box-sizing: inherit;
  text-align: center;
  flex-basis: 14.2857%;
}

.rdrDays {
  flex-wrap: wrap;
  display: flex;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
  outline: none;
}

.rdrDateInput .rdrWarning {
  color: red;
  font-size: 1.6em;
  line-height: 1.6em;
  position: absolute;
  top: 0;
  right: .25em;
}

.rdrDay {
  box-sizing: inherit;
  font: inherit;
  cursor: pointer;
  width: 14.2857%;
  position: relative;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  -webkit-user-select: none;
  user-select: none;
  display: inline-flex;
}

.rdrStaticRanges {
  flex-direction: column;
  display: flex;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: flex;
}

.rdrCalendarWrapper {
  color: #000;
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  background-color: #eff2f7;
}

.rdrDateDisplay {
  margin: .833em;
}

.rdrDateDisplayItem {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 4px;
  box-shadow: 0 1px 2px #23394236;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  color: #849095;
  background: none;
  border: 0;
  width: 100%;
  height: 2.5em;
  line-height: 2.5em;
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
}

.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  appearance: none;
  color: #3e484f;
  cursor: pointer;
  text-align: center;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") right 8px center no-repeat;
  border: 0;
  border-radius: 4px;
  outline: 0;
  padding: 10px 30px 10px 10px;
}

.rdrMonthAndYearPickers select:hover {
  background-color: #00000012;
}

.rdrMonthPicker, .rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  background: #eff2f7;
  border: 0;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  margin: 0 .833em;
  padding: 0;
  display: block;
}

.rdrNextPrevButton:hover {
  background: #e1e7f0;
}

.rdrNextPrevButton i {
  text-align: center;
  border-style: solid;
  width: 0;
  height: 0;
  margin: auto;
  padding: 0;
  display: block;
  transform: translate(-3px);
}

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: #0000 #34495e #0000 #0000;
  transform: translate(-3px);
}

.rdrNextButton i {
  border-width: 4px 4px 4px 6px;
  border-color: #0000 #0000 #0000 #34495e;
  margin: 0 0 0 7px;
  transform: translate(3px);
}

.rdrWeekDays {
  padding: 0 .833em;
}

.rdrMonth {
  padding: 0 .833em 1.666em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  color: #849095;
  font-weight: 400;
  line-height: 2.667em;
}

.rdrDay {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  color: #1d2429;
  background: none;
  border: 0;
  height: 3em;
  padding: 0;
  line-height: 3em;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  display: flex;
  position: absolute;
  inset: 5px 0;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span:after {
  content: "";
  background: #3d91ff;
  border-radius: 2px;
  width: 18px;
  height: 2px;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%);
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #ffffffd9;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: currentColor;
  position: absolute;
  inset: 5px 0;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected {
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
  z-index: 1;
  background: #ffffff17;
  border: 0 solid;
  position: absolute;
  inset: 3px 0;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0;
}

.rdrDefinedRangesWrapper {
  background: #fff;
  border-right: 1px solid #eff2f7;
  width: 226px;
  font-size: 12px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 600;
}

.rdrStaticRange {
  cursor: pointer;
  background: #fff;
  border: 0;
  border-bottom: 1px solid #eff2f7;
  outline: 0;
  padding: 0;
  display: block;
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  text-align: left;
  outline: 0;
  padding: 10px 20px;
  line-height: 18px;
  display: block;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  text-align: center;
  color: #6c767a;
  border: 1px solid #dee7eb;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  line-height: 30px;
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover {
  color: #333;
  border-color: #b4bfc4;
  outline: 0;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: "";
  background: none;
  border: 1px solid;
  border-radius: 1.333em;
  position: absolute;
  inset: -2px 0;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: #f8f8f8;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
}

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale() opacity(60%);
}

.rdrMonthName {
  text-align: left;
  color: #849095;
  padding: .833em;
  font-weight: 600;
}
/*# sourceMappingURL=index.a8ac2c40.css.map */
