.order-info {
  background-color: #FFFFFF;
  border: 1px solid #CFCFCF;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 12px 12px 6px 1px rgba(201,201,201,0.5);
  /* font-size: 12px; */
  background-color: #f9f9f9;
  min-height: 40px;
  min-width: 100%;
  /* z-index: 1; */
}

.order-lookup-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.order-lookup-search input {
  flex-grow: 1;
}

.order-lookup-search button {
  width: 100px;
  flex-shrink: 0;
  margin-top: 0;
}

.order-lookup-results {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}

.no-results {
  font-size: 18pt;
}

.order-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #CFCFCF;
}

.order-identifiers {
  padding: 10px;
  text-align: left;
}

.order-status-group {
  text-align: right;
}

.order-number {
  display: block;
  font-size: 20px;
}

.frn {
  display: block;
  color: grey;
}

.fulfillment-type {
  display: block;
  color: grey;
}

.order-status {
  font-size: 20px;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding-left: 10px;
}

.item-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #CFCFCF;
}

.order-image img {
  width: 200px;
  height: 200px;
  margin-right: 10px;
}

.item-info {
  display: flex;
  flex-direction: row;
  align-items: left;
  text-align: left;
}

.item-identifiers {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.item-status {
  text-align: right;
}

.item-status .status {
  font-size: 18px;
}

.fulfillment {
  border-top: 1px solid #CFCFCF;
}

.fulfillments {
  text-align: left;
}

.order-actions {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Optional: Aligns items vertically in the center */
}

.tracking-number {
  padding: 10px;
}

.order-actions button {
  margin-right: 8px;
}

.json-buttons {
  padding: 10px;
}

.json-buttons > div {
  margin: 5px 0px;
}

.order {
  float: left;
}

.order-json {
  height: 500px;
  width: 100%;
}