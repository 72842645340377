
.popup-content {
  background-color: #FFFFFF;
  border: 1px solid #CFCFCF;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 6px 1px rgba(201,201,201,0.5);
  display: none;
  font-size: 12px;
  position: absolute;
  background-color: #f9f9f9;
  min-height: 40px;
  min-width: 100px;
  z-index: 1;
  }

  .popup-content li span{
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
 
  .popup .popup-content ul li:hover {
    background-color: #DEEBFF
  }

.popup:hover .popup-content {
  display: block;
 }
 
 .success-message {
    background-color: #DCFDEA;
    border: 1px solid #27AE60;
    border-radius: 2px;
    box-sizing: border-box;
    color: #27AE60;
    float: right;
    font-size: 12px;
    font-weight: bold;
    padding: 8px;
    width: 228px;
 }
 