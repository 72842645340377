html,body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
}
.sidebar {
  background: #FA5400;
  box-shadow: 1px 0px 7px #111111;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: visible;
  padding: 5px 5px 5px 15px;
  position: fixed;
  text-align: left;
  top: 0;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  width: 55px;
  word-wrap: unset;
  z-index: 9999;
}
.sidebar svg {
  height: 48px;
}
.label {
  vertical-align: top;
  display: none;
  transform: translateX(-100%);
}
.sidebar:hover {
  width: 280px;
}
.sidebar:hover .label {
  display: inline-block;
  padding-left: 12px;
  padding-top: 12px;
  transform: translateX(0);
}
.sidebar:focus {
  outline: none
}
.sidebar a {
  color: #E5E5E5;
}
.sidebar a:hover, .sidebar a:active {
  color: #333333;
}
.sidebar-item {
  color: #E5E5E5;
  height: 48px;
  overflow: hidden;
  transition: all 0.7s ease;
}
.subnav {
  display: none;
  margin-left: 29px;
}
.sidebar-item:hover .subnav {
  display: block;
}
