.tool-tip .tool-tip-content {
    background-color: #F9F9F9;
    border: 1px solid #CFCFCF;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: 0 0 6px 1px rgba(201,201,201,0.5);
    display: none;
    font-size: 12px;
    padding: 10px;
    position: absolute;
    margin-left: 80px;
    margin-top: 30px;
    min-height: 70px;
    min-width: 400px;
    z-index: 1;
}

.tool-tip:hover .tool-tip-content {
    display: block;
}

.tool-tip svg {
  background-color: #000000;
  border-radius: 50%;
  color: #FFFFFF;
  cursor: pointer;
  height: 16px;
  left: 100px;
  margin-top: 8px;
  position: absolute;
  width: 16px;
  z-index: 1;
}