.bulk-edit-article-height {
    height: 30vw;
}

/* Key List Styles */
.key-list {
    height: 25vw; /* Set a max height for the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ccc; /* Add a border */
    border-radius: 4px; /* Round the corners */
    width: 100%; /* Make it full width */
    margin-right: 50px;
  }
  
  .key-value {
    width: 100%;
    height: 100%; /* Set a max height for the scrollable area */
    margin-left: 50px;
  }
  
  .key-item, .edit-key-item{
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .key-item:hover {
    background-color: #f49a6d; /* Highlight on hover */
  }

  .edit-key-item:hover {
    background-color: #ececec; /* Highlight on hover */
    /* color: rgb(252, 251, 251); */
  }
  
  .key-item.selected {
    background-color: #fa5400; /* Highlight selected item */
    color: rgb(252, 251, 251); /* Change text color for selected item */
    font-weight: bolder;
  }

  /*Filter overlay styles */
  .filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
  }
  
  .filter-overlay-content {
    position: relative;
    background: white;
    padding: 20px;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 8px;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .close-container {
    position: absolute;
    top: 14px; 
    right: 14px; 
    cursor: pointer;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .button-container button {
    margin: 0 10px;
  }

  .overlay-select {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /**/

  .show-filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    margin-left: 40px;
}

.show-filter-item {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid black;
    position: relative;
}

.parent-edit-class .react-select__placeholder{
  color: #cac9c9 !important;
}

.bulk-chip {
  display: inline-flex;
  align-items: center;
  background-color: #707072;
  color: white;
  border-radius: 14px;
  padding: 4px 2px 4px 6px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.bulk-chip .bulk-close-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  line-height: 1;
  cursor: pointer;
  color: #707072;
  background-color: white;
  border-radius: 50%;
}

.bulk-chip .bulk-close-icon:hover {
  color: #0a0606;
}
