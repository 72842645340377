/* Add this CSS to your stylesheet */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
}

.overlay-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay-content-loading {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overlay-content h2 {
  margin-top: 0;
}

/* Tooltip container */
.tooltip-custom {
  padding-top: 10;
  position: relative;
  cursor: pointer;
  float: right;
}

/* Tooltip text */
.tooltip-custom .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip-custom .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-custom:hover .tooltiptext {
  visibility: visible;
}

.outer-container {
  display: flex;
  justify-content: center; /* Optional: Adjust spacing between the divs */
  align-items: baseline;
}

.download-icon {
  height: 36px;
  width: 36px;
}

.create-key {
  padding-top: 0%;
  margin-top: 0%;
  text-align: right;
}

.create-key-button {
  padding-top: 0%;
  margin-top: 0%;
  right: 0px;
  text-align: right;
}

.input-container {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
  padding-top: 10px;
}

.input-container > * {
  flex: 2;
  padding-inline: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.button-container button {
  margin: 0 10px;
}

.toggle-container {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.toggle-container > * {
  flex: 2;
  padding-inline: 20px;
}

.select-container {
  text-align: left;
  position: relative;
  z-index: 1004;
}

.select-container-valueType {
  text-align: left;
  position: relative;
  z-index: 1003;
}

.select-container-group{
  text-align: left;
  position: relative;
  z-index: 1002;
}

.select-container-value{
  text-align: left;
  position: relative;
  z-index: 1001;
}


.req-paragraph {
  padding-top: 10px;
  color: red;
  font-size: 12px;
}

.loading {
  padding-top: 24px;
}


.container {
  text-align: center;
}

.toggle{ 
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 30px;

}
.custom-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  padding: 0;
}

.custom-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.custom-toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.custom-toggle input:checked + .custom-toggle-slider {
  background-color: #000000;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  transform: translateX(26px);
}

.toggleLabel {
  font-size: 14px;
  margin-right: 10px;

}

.close-container {
  position: absolute;
  top: 14px; /* Adjust as needed */
  right: 14px; /* Adjust as needed */
  cursor: pointer;
}
