.country-stores-container {
    border: 2px solid black;
    padding : 20px;
    width: 300px;
    margin: auto;
}

.reset-button {
    display: block;
    margin: 10px auto;
    padding: 5px 10px;
}

.country-section {
    margin-bottom: 20px;
}

.country-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.country-header h3 {
    margin: 0;
}

.trash-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.stores-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px;
}

.store-item {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid black;
    position: relative;
}

.delete-store-button {
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
}