.rectangle {
  box-sizing: border-box;
  border: 1px solid #cfcfcf;
  margin: 10px;
  border-radius: 2px;
  background-color: #ffffff;
}

.rectangle li {
  border-bottom: 1px solid #cfcfcf;
}

.rectangle li:last-child {
  border-bottom: none;
}
